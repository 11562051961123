import React from 'react';


const Mobile = () =>{
    return (
        <>
            <p>Sorry! The mobile site is under construction.</p>
        </>
    );
}


export default Mobile;